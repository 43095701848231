.navbar {
  display: grid;
  grid-template-columns: 64px auto auto;
  align-items: center;
  flex-direction: column;
  height: var(--navheight);
  background-color: var(--primary);

  a {
    transition: 0.2s;
    text-decoration: none;
    color: white;
  }

  .logo {
    display: flex;
    justify-content: flex-start;
    right: 0;
    margin-left: 0.75rem;
  }

  .links {
    display: flex;
    gap: 1rem;

    a {
      padding: 0.5rem 0.75rem;
      border-radius: 5px;

      &:hover {
        background-color: var(--secondary);
        color: var(--text-secondary);
      }
    }
  }

  .accountControl {
    margin-left: auto;
    margin-right: 0.75rem;
    display: flex;
    gap: 15px;

    a {
      padding: 0.5rem 0.75rem;
      border-radius: 5px;
      background-color: var(--secondary);

      &:hover {
        background-color: var(--accent);
        color: var(--text-secondary);
        cursor: pointer;
      }
    }
  }

  .logo,
  img {
    height: 2rem;
    width: 2rem;
  }
}
