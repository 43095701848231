.uploadContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - var(--navheight));

  .pageHeader {
    font-size: 2rem;
    margin-top: 25px;
  }

  .uploadInput {
    label {
      &:hover {
        cursor: pointer;
      }
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;

      i {
        font-size: 3rem;
        margin-bottom: 15px;
      }
    }
    display: flex;
    height: 50%;
    background-color: var(--accent);
    transition: 0.2s;
    &:hover {
      opacity: 80%;
    }
    border: 2px solid var(--primary);
    border-radius: 25px;
    width: 50%;
  }

  .submitUpload {
    margin-top: 25px;
    width: 25%;
    button {
      width: 100%;
      border: 0;
      border-radius: 15px;
      padding: 15px;
      background-color: var(--primary-btn);
      color: white;
      font-size: 1.2rem;
      transition: 0.2s;

      &:hover {
        cursor: pointer;
        background-color: var(--primary-btn-hvr);
      }
    }
  }
}
