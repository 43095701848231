/*

Primary: #1a1a1e;
Secondary: #252528;
Accent: #4e4e4e;

Buttons / Links: #3D9CEF
Buttons / Links (Hover): #3186d1 

Destroy: #ae3838
Destroy (Hover): #8c2525

Modify: #bb610d
Modify (Hover): #88490e

*/

:root {
  --primary: #1a1a1e;
  --secondary: #252528;
  --tertiary: #242424;

  --list-item: #2b2b2b;
  --list-item-hvr: #3b3b3b;

  --accent: #363f49;
  --light-accent: #798da3;

  --text-primary: white;
  --text-secondary: #7ec3ff;

  --primary-btn-border: #0074db;
  --primary-btn: #0058a8;
  --primary-btn-hvr: #006bc9;

  --destroy-btn: #bc3f3f;
  --destroy-btn-hvr: #8c2525;

  --modify-btn: #bb610d;
  --modify-btn-hvr: #88490e;

  --green: #36c75c;
  --red: #ff4545;

  --navheight: 60px;
}

.pageHeader {
  font-size: 2rem;
  margin: 25px 25px 0 25px;
}

.seperator {
  width: 20%;
  height: 2px;
  margin: 15px 0;
  background-color: var(--accent);
}

html {
  font-size: 100%;
  background-color: var(--secondary);
}

body {
  color: white;
  margin: 0;
  padding: 0;
  /* padding-bottom: 75px !important; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: white;
}
