.domainCreationContainer {
  display: flex;
  width: 80%;
  max-height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

  .pageHeader {
    font-size: 2rem;
    margin-top: 25px;
  }

  .valueInput {
    width: 30%;

    .inputHeader {
      font-size: 1.25rem;
      margin: 10px 0;
      text-align: center;
    }

    .inputSSLONNotice,
    .inputSSLOFFNotice,
    .inputSSLNotice {
      text-align: center;
      margin-bottom: 15px;
      color: var(--text-secondary);
      font-size: 1rem;
      font-weight: 400;
    }

    .inputOptions {
      display: flex;
      flex-direction: column;
      width: 100%;

      .radio {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      input:checked ~ .inputSSLNotice {
        display: none;
      }

      #sslOn:checked ~ .inputSSLONNotice {
        display: block !important;
      }

      #sslOn:checked ~ .inputSSLONNotice {
        display: none;
      }

      #sslOff:checked ~ .inputSSLOFFNotice {
        display: block !important;
      }

      #sslOn:checked ~ .radio label[for="sslOn"] {
        border: 2px solid var(--green);
      }

      #sslOff:checked ~ .radio label[for="sslOff"] {
        border: 2px solid var(--red);
      }

      .sslToggleOption {
        text-align: center;
        background-color: var(--accent);
        width: 40%;
        padding: 10px 12px;
        border-radius: 5px;
        border: 2px solid var(--primary);
        user-select: none;
        cursor: pointer;
      }
    }

    &#certUpload {
      opacity: 0;
      max-height: 0;
      transition: 0.5s;

      .inputOptions {
        display: flex !important;
        justify-content: space-between;
        flex-direction: row;

        label {
          span[id$="FileStatus"] {
            &.complete {
              color: var(--green) !important;
            }
          }
          text-align: center;
          background-color: var(--accent);
          width: 40%;
          padding: 10px 12px;
          border-radius: 5px;
          border: 2px solid var(--primary);
          user-select: none;
          cursor: pointer;
        }
      }
    }

    &#submit input {
      margin-top: 30px;
      background-color: var(--primary-btn);
      cursor: pointer;

      &:hover {
        background-color: var(--primary-btn-hvr);
      }
    }
  }

  input {
    color: white;
    width: 100%;
    text-align: center;
    border: 2px solid var(--primary);
    border-radius: 10px;
    outline: 0;
    padding: 10px 0px;
    background-color: var(--accent);

    font-size: 1rem;
    font-weight: 500;
  }

  input:not(:valid) {
    border: 1px solid var(--red);
  }
}
