.domainControls {
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .containerHeader {
    user-select: none;
    border-bottom: 2px solid var(--accent);
    border-radius: 5px 5px 0 0;
    user-select: none;
    text-align: center;
    padding: 12px 0;
    background-color: var(--tertiary);

    .domainName {
      color: var(--text-secondary);
    }
  }

  .subdomainList {
    .subdomainAddition {
      text-align: center;
      padding: 12px 0;
      background-color: var(--tertiary);
    }

    .subdomainAddition {
      .fa-circle-plus {
        color: var(--green);
      }
      &:hover {
        cursor: pointer;
      }
      margin-top: auto;
      border-top: 2px solid var(--accent);
    }

    display: flex;
    flex-direction: column;
    border: 2px solid var(--accent);
    background-color: var(--primary);
    width: 30%;
    height: 50vh;
    border-radius: 5px;

    .subdomains {
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      a {
        &:first-child {
          border: none;
        }
        display: flex;
        justify-content: center;
        padding: 11.8px 0;
        width: 100%;
        background-color: var(--list-item);

        & .subdomainName {
          width: 60%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover,
        &.active {
          background-color: var(--list-item-hvr);
        }
        border-top: 2px solid var(--accent);
      }
    }

    .subdomainSettings {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
  }

  .subdomainManagement {
    border: 2px solid var(--accent);
    background-color: var(--primary);
    width: 60%;
    height: 50vh;
    border-radius: 5px;

    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text-secondary);
    }

    .subdomainSettings {
      .actions,
      .sslToggle,
      .sslCerts {
        &.sslToggle,
        &.sslCerts {
          .sectionHeader {
            width: 100%;
            text-align: center;
            background-color: var(--tertiary);
            padding: 12px 0;
            border: 1px solid var(--accent);
            border-left: 0;
            border-right: 0;
          }

          .inputOptions {
            width: 80%;
            display: flex !important;
            justify-content: space-between;
            flex-direction: row;

            label, button {
              text-align: center;
              background-color: var(--accent);
              width: 30%;
              padding: 10px 12px;
              border-radius: 5px;
              border: 2px solid var(--primary);
              user-select: none;
              cursor: pointer;

              &.selected {
                color: var(--green);
              }
            }

            button:disabled {
              pointer-events: none;
              opacity: 0.5;
            }
          }

          flex-direction: column;
        }

        display: flex;
        margin: 16px 0;
        justify-content: center;
        align-items: center;
        gap: 16px;
      }

      a,
      button {
        border: 0;
        outline: 0;
        padding: 0.5rem 0.75rem;
        border-radius: 5px;
        transition: 0.2s;
        color: white;
        background-color: var(--accent);

        &:hover {
          &.delete {
            color: var(--destroy-btn);
          }

          cursor: pointer;
          background-color: var(--secondary);
          color: var(--text-secondary);
        }
      }
    }
  }
}

.subdomainCreate {
  position: absolute;
  display: block;
  top: var(--navheight);
  width: 100vw;
  height: calc(100vh - var(--navheight));
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);

  .subdomainDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 80vh;

    & .subContent {
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 15px;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 30px;

      div {
        padding: 5px;

        input {
          outline: none;
          border: none;

          padding: 5px;
          border-radius: 5px;
        }

        button {
          transition: 0.2s;
          outline: none;
          border: none;
          color: white;
          padding: 8px 16px;
          border-radius: 5px;
          margin: 0 5px;
          background-color: var(--primary-btn);
          &:hover {
            background-color: var(--primary-btn-hvr);
          }
          cursor: pointer;
        }

        button#cancel {
          background-color: var(--accent);
          &:hover {
            background-color: var(--light-accent);
          }
        }
      }
    }
  }

  .close {
    position: absolute;
    z-index: 101;
    right: 0;
    margin: 12px 16px;
    padding: 8px 12px;

    &:hover {
      cursor: pointer;
    }
  }
}

.primaryDomain {
  position: relative;
  margin-right: -30px;
  right: 40px;
  background-color: var(--primary-btn-hvr);
  font-size: 0.8rem;
  height: 18px;
  padding: 2px 12px;
  border-radius: 15px;
}
