.domainManagementContainer {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

  .deleteDomain {
    display: flex;
    margin-top: 15px;
    
    background-color: var(--destroy-btn);
    &:hover {
      background-color: var(--destroy-btn-hvr);
      cursor: pointer;
    }

    padding: 6px;
    border-radius: 5px;

    i {
      display: flex;
      font-size: 1.3rem;
      margin-right: 5px;
    }
  }

  .domainList {
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    font-size: 1.25rem;

    .managableDomainCreate {
      .domainStatus {
        label {
          margin-left: 5px;
        }

        &.active {
          color: var(--primary-btn) !important;
        }
        &.inactive {
          color: var(--destroy-btn);
        }
      }
    }

    .managableDomain,
    .managableDomainCreate {
      display: grid;
      grid-template-columns: 8rem 16rem;
      width: inherit;
      flex-direction: row;
      margin: 10px;

      transition: 0.5s;

      &:hover {
        color: var(--text-secondary);
        background-color: var(--light-accent);
      }

      background-color: var(--primary);
      padding: 6px 10px;
      border-radius: 15px;

      .domainStatus {
        width: 100%;

        white-space: nowrap;

        label {
          margin-left: 5px;
        }

        &.active {
          color: var(--green);
        }
        &.inactive {
          color: var(--destroy-btn);
        }
      }

      div {
        padding: 6px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
