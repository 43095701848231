@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fa-spinner {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 1000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 1000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.profileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  margin-top: 25px;
  width: 70%;
  background-color: var(--primary);
  border-radius: 5px;

  .profileHeader {
    display: flex;
    @media (max-width: 1200px) {
      justify-content: center;
    }
    align-items: center;
    width: 100%;

    .userInfo {
      display: flex;
      // width: 100%;
      gap: 15px;
      margin: 15px 0;
      @media (max-width: 1200px) {
        margin: 15px 15px;
        gap: 15px 0;
        flex-direction: column;
        width: 100%;
      }

      div {
        display: flex;
        border-radius: 5px;
        align-items: center;
        padding: 10px;
        background-color: var(--secondary);
        gap: 10px;
        font-weight: 500;

        span {
          transition: width 0.2s ease-out;
          color: rgb(255, 255, 255);
          border-radius: 25px;
          background-color: var(--primary-btn);
          padding: 5px 10px;
          font-weight: normal;
        }
        @media (min-width: 1200px) {
          &:first-child {
            margin-left: 15px;
          }
        }
      }
    }

    .userStatContainer {
      display: flex;

      justify-content: center;
      align-items: center;
      padding: 15px 0;

      width: 65%;

      .userStatsText {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        width: 100%;

        div {
          display: flex;
          border-radius: 5px;
          width: max-content;
          align-items: center;
          padding: 15px;
          background-color: var(--secondary);

          &:last-child {
            margin-right: 15px;
          }
        }
      }
    }
  }

  .fileSizeProgressBar {
    background-color: var(--accent) !important;
    width: calc(100% - 30px);
    height: 40px;
    border-radius: 5px;

    .progressLabel {
      display: flex;
      width: 100%;
      height: 40px;
      position: absolute;
      margin: auto;
      left: 0;
      text-align: center;
      justify-content: center;
      align-items: center;
      gap: 25px;
      font-size: 14px;

      span {
        opacity: 80%;
        background-color: var(--secondary);
        padding: 1px 10px;
        border-radius: 5px;
      }
    }

    .container {
      display: flex;

      .progress {
        display: flex;
        transition: 1s;
        border-radius: 5px;
        width: 0%;
        height: 40px;
        background-color: var(--green);
      }
    }
  }

  .profileUpdates {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--secondary);
    width: calc(100% - 30px);
    margin: 15px;
    border-radius: 5px;

    .containerHeader {
      width: 100%;
      text-align: center;
      padding: 15px 0;
      font-size: 1.2rem;
      font-weight: 500;
    }

    .analyticsContainer {
      display: flex;
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0 auto 15px auto;
      justify-content: center;
      align-items: center;
      height: 300px;
      width: calc(100% - 30px);
      background-color: var(--accent);
      border-radius: 15px;
    }

    .mostRecentUploads {
      display: flex;
      flex-direction: column;
      .uploads {
        display: flex;
        @media (max-width: 1200px) {
          flex-wrap: wrap;
          gap: 15px;
          width: 100%;
          justify-content: center;
        }
        justify-content: space-between;
        flex-direction: row;

        .end {
          .fileName {
            font-weight: 500;
          }

          background-color: var(--primary-btn) !important;
          &:hover {
            opacity: 80% !important;
            background-color: var(--primary-btn-hvr);
            cursor: pointer;
          }
        }

        .uploadPreview {
          @media (min-width: 1200px) {
            &:first-child {
              margin-left: 15px;
            }

            &:last-child {
              margin-right: 15px;
            }
          }
          @media (max-width: 1200px) {
            margin: 25px;
          }
          &:hover {
            transition: 0.2s;
            opacity: 80%;
            cursor: pointer;
          }

          border: 2px solid var(--light-accent);
          background-color: var(--accent);
          border-radius: 15px;

          .file {
            --size: calc(7vh + 7vw);
            @media (max-width: 1200px) {
              --size: calc(12vh + 12vw);
            }
            width: var(--size);
            height: var(--size);
            display: flex;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
            border-radius: 15px 15px 0 0;
            transition: opacity 0.5s;
            &.loaded {
              opacity: 0;
            }

            i {
              --size: calc(4vh + 4vw);
              display: flex;
              font-size: var(--size);
              color: white;
              justify-content: center;
              align-items: center;
            }
          }
          .fileName {
            display: flex;
            padding: 5px 0;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
  }
}
