.errorContainer {
  display: flex;
  gap: 1.5rem;
  min-height: calc(80vh - var(--navheight));
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .errorTitle {
    font-size: 3rem;
    font-weight: 600;
  }

  .errorDescription {
    color: var(--text-secondary);
    font-size: 2rem;
  }

  .errorButtons {
    display: flex;
    gap: 15px;
    margin-top: 30px;
    a {
      padding: 12px 16px;
      border-radius: 5px;
      background-color: var(--primary-btn);
      margin: auto;
      transition: 0.2s;
      &:hover {
        border-radius: 7px;
        background-color: var(--primary-btn-hvr);
      }
    }
  }
}
