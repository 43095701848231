.landingContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--secondary);
  min-height: calc(100vh - var(--navheight));
  // padding-bottom: 75px;

  .siteHeading {
    margin: 0.5rem;
    display: block;
    font-size: 1.5rem;
    color: var(--text-secondary);

    .siteName {
      font-size: 2rem;
      font-weight: bolder;
      color: white;
    }
  }

  .siteLogo {
    margin-top: 2.5rem;
    img {
      width: 12rem;
      height: 12rem;
    }
  }

  .siteSlogan {
    margin-bottom: 0.8rem;
    justify-content: center;
    text-align: center;
    display: flex;
    font-size: 1.4rem;
    width: 60%;
  }

  .links {
    margin: 0.5rem;
    a {
      border: 2px solid var(--primary-btn-border);
      margin: 0 10px;
      text-decoration: none;
      color: white;
      font-size: 1.1rem;
      background-color: var(--primary-btn);
      padding: 8px 12px;
      border-radius: 5px;
      transition: 0.2s;

      &:hover {
        background-color: var(--primary-btn-hvr);
      }
    }
  }

  .plans {
    display: flex;
    gap: 2rem;
    flex-direction: row;

    margin-top: 1rem;

    .planCard {
      padding: 1rem 2rem;
      border: 1px solid var(--accent);
      border-radius: 12px;
      width: 16rem;
      height: 15.5rem;

      background-color: var(--primary);

      div {
        width: 100%;
        font-size: 1.5rem;
        text-align: center;
      }

      .planPrice {
        padding: 1rem 0;
        font-size: 1.35rem;
        color: var(--text-secondary);
      }

      .planContents {
        margin-bottom: 0.5rem;
        div {
          font-size: 1.25rem;
        }
      }

      .planLink {
        padding: 1rem 0;
        font-size: 1.2rem;
        margin-top: auto;
        a {
          border: 2px solid var(--primary-btn-border);
          border-radius: 20px;
          padding: 0.5rem 1rem;
          text-decoration: none;
          color: white;
          background-color: var(--primary-btn);
          transition: 0.2s;

          &:hover {
            background-color: var(--primary-btn-hvr);
          }
        }
      }
    }
  }
}
