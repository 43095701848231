.filesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh - var(--navheight);
  justify-content: center;
  align-items: center;

  .loading, .noFiles {
    font-size: 1.5rem;
  }

  .uploads {
    width: 70%;
    display: flex;
    gap: 50px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .filePreview {
      border: 2px solid var(--light-accent);
      width: 200px;
      height: 270px;
      border-radius: 7px;

      .file {
        border-radius: 5px 5px 0 0;
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: contain;
        background-position: center;
        overflow: hidden;
        img {
          width: 200px;
          height: 200px;
        }
      }

      .fileName {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        background-color: var(--accent);
      }

      .fileControls {
        display: flex;
        width: 100%;
        height: 40px;

        a {
          justify-content: center;
          align-items: center;
          display: flex;
          background-color: var(--primary-btn);
          width: 100px;
          height: 40px;

          border-radius: 0 0 0 5px;

          &.active {
            opacity: 0.6;
          }
        }

        button {
          color: white;
          width: 50px;
          height: 40px;
          border: none;
          padding: none;

          &.rename {
            background-color: var(--modify-btn);
          }

          &.delete {
            background-color: var(--destroy-btn);
            border-radius: 0 0 5px 0;
          }
        }
      }
    }
  }

  .paginator {
    display: flex;
    padding: 15px 0;
    gap: 15px;
    user-select: none;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a {
      transition: 0.2s;
      padding: 8px;
      background: var(--accent);
      border: 2px solid var(--light-accent);
      border-radius: 5px;

      &.active {
        opacity: 60%;
      }

      &:hover {
        opacity: 60%;
      }
    }
  }
}
