.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: calc(100vh - var(--navheight));

  .inputModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: -2rem;

    height: fit-content;
    min-width: 16rem;

    background-color: var(--primary);
    color: white !important;
    padding: 12px 24px;

    border-radius: 12px;

    .inputContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      margin: 5px 0;

      label {
        align-self: flex-start;
        color: var(--text-primary);
      }
    }

    input {
      color: white;

      margin: 5px 0;
      padding: 8px 0;
      text-align: center;
      width: 100%;

      border: 0;
      border-radius: 5px;
      background-color: var(--accent);

      &:hover,
      &:active,
      &:focus {
        outline: none;
      }
    }

    .modalHeader {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .submitButton {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      button {
        transition: 0.2s;

        &.signup {
          background-color: var(--light-accent) !important;
        }

        &:hover {
          opacity: 60%;
          cursor: pointer;
        }

        margin: 10px 0px;
        width: 45%;
        color: white;
        font-size: 1rem;

        background-color: var(--primary-btn);

        padding: 10px 0px;

        border: 0;
        border-radius: 5px;
        outline: 0;
      }
    }

    .accountRecovery {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 5px;

      label {
        opacity: 50%;
      }

      a {
        color: var(--text-secondary);
        opacity: 50%;
        transition: 0.5s;
        text-decoration: none;
        &:hover {
          opacity: 80%;
        }
      }
    }
  }
}
